.main-loader {
  z-index: 9999999999;
  background-color: #00000070;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .loader {
    width: 48px;
    height: 48px;
    margin: auto;
    opacity: 1;
    position: relative;
    &:before {
      content: "";
      width: 48px;
      height: 5px;
      background: #999;
      position: absolute;
      top: 60px;
      left: 0;
      border-radius: 50%;
      animation: shadow324 0.5s linear infinite;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      animation: jump7456 0.5s linear infinite;
    }
  }
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}
::-webkit-scrollbar {
  display: none;
}

.dialog .mainDiaogBox .dialog-body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
    display: block;
    background-color: #a2a2a2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #a2a2a2;
  }
  &::-webkit-scrollbar {
    height: 5px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #a2a2a2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b7b6b6;
  }

  &::-webkit-scrollbar:vertical {
  }
}
.custom-table {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
    display: block;
    background-color: #a2a2a2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #a2a2a2;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #a2a2a2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b7b6b6;
  }

  &::-webkit-scrollbar:vertical {
  }
}

.label-input {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #7b7b7b;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 1px;
}
.cancel-btn {
  border-radius: 8px;
  padding: 10px 23px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(180deg, #ea1919 0%, #f91d1d 100%);
  color: #fff;
  font-size: 15px;
}

.male-btn {
  border-radius: 8px;
  padding: 10px 23px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: blue;
  color: #fff;
  font-size: 15px;
}

.female-btn {
  border-radius: 8px;
  padding: 10px 23px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: rgb(228, 17, 158);
  color: #fff;
  font-size: 15px;
}
.add-btn {
  border-radius: 8px !important;
  color: white;
  outline: none;
  border: none;
  background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
  padding: 13px;
  display: flex;
  align-items: center;
  height: 40px;
}
.editBtn {
  width: 39px !important;
  height: 39px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;
  background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%) !important;
  margin: auto;
}
.deleteBtn {
  width: 39px;
  height: 39px;
  border-radius: 10px !important;
  background: radial-gradient(
    486px at 37.5% -17.2%,
    rgb(255, 2, 2) 0%,
    rgb(254, 78, 172) 90%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    font-size: 21px;
  }
}

.image-preview {
  i {
    color: white;
    position: absolute;
    right: 9px;
    top: 4px;
    cursor: pointer;
  }
}
.iconImage {
  box-shadow: rgba(105, 103, 103, 0.5) 0px 5px 15px 0px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 10px;
  float: left;
  object-fit: contain;
  margin-right: 15px;
}
.img_container {
  display: inline;
  position: relative;
  float: left;
}
.Toastify {
  .Toastify__toast-body {
    text-transform: capitalize;
    div {
      font-size: 14px !important;
    }
  }
}
.cursor-pointer{
  cursor: pointer!important;
}
.submit-btn {
  border-radius: 8px !important;
  padding: 10px 23px !important;
  outline: none !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%) !important;
  color: #fff !important;
  font-size: 15px !important;
}
.new-btn {
  font-weight: 600;
  border-radius: 8px;
  color: white;
  outline: none;
  border: none;
  background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
  padding: 13px;
  font-weight: normal;
  display: flex;
  align-items: center;
  height: 40px;
  svg {
    margin-right: 3px;
  }
  span {
    font-size: 15px !important;
  }
}

.simple-table {
  border-radius: 16px;
  margin-top: 15px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  position: relative;
  max-height: 776px;
  display: block;
  overflow: auto;
  min-height: 462px;
  height: 100%;
  table {
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      color: #fff;
      border-radius: 16px 16px 0px 0px;
      width: 100%;
      background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
      th {
        white-space: nowrap;
        padding: 12px 16px;
        text-align: center;
        &:nth-child(1) {
          border-radius: 16px 0px 0px 0px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    tbody {
      td {
        padding: 12px 20px;
        text-align: center;
        border: 1px solid #f4f4f4;
        span {
          color: #585858;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          opacity: 0.8;
        }
      }
    }
  }
}

.back-btn {
  font-weight: 600;
  border-radius: 8px;
  color: white;
  outline: none;
  border: none;
  background: linear-gradient(95deg, #0e203c 0%, #0f2246 100%);
  padding: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  height: 40px;

  span {
    font-size: 15px !important;
  }
}
.dayAnalytics .MuiInputBase-root {
  border-radius: 64px !important;
}
.dayAnalytics .MuiFormControl-root .Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}
.dayAnalytics #dayAnalytics-select {
  display: flex;
  justify-content: center;
}
.dayAnalytics .MuiOutlinedInput-notchedOutline {
  border-color: var(--lightOrangeColor);
}
/* .dayAnalytics svg{
  background-color: var(--lightOrangeColor);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
    width: 50px;
    height: 100%;
    border-radius: 68px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
 } */
.dayAnalytics .MuiSelect-iconOpen {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 68px;
}
.dayAnalytics .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff9eac !important;
}
.dayAnalytics .MuiInputBase-root:hover {
  border-color: #ff9eac !important;
}
.dayAnalytics .MuiInputBase-root {
  height: 43px;
  width: 190px;
}
.dayAnalytics .MuiBox-root {
  display: flex;
  justify-content: flex-end;
}
.dayAnalytics .Mui-focused .MuiOutlinedInput-notchedOutline {
  color: rgba(0, 0, 0, 0.54) !important;
}


.label{
  color: #7b7b7b;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    margin-left: 1px;
}