$md: 768px;
$theme: #0570d4;
$second: #0f7085;
$border: #c5c5c5;
$focusBorder: #bab5b5;

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}
.MuiAutocomplete-option {
  font-size: 13px;
  color: #4b4d58;
  font-size: 14px;
}
// input
.errorMessage {
  margin-top: 6px;
  font-size: 13px;
  color: red;
  margin-left: 1px;
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

.custom-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-top: 10px;

  .switch-toggle-input {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .toggle {
      margin-right: 8px;
    }
    label {
      margin: 0px;
    }
  }
  label {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #7b7b7b;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 1px;
  }
  input {
    border-radius: 6px;
    border: 1px solid $border;
    background: #fff;
    font-size: 13px;
    padding: 15px;
    outline: none;
    height: 38px;
    width: 100%;
    font-size: 14px;
    &:focus {
      background-color: white;
      border-color: $focusBorder !important;
    }
  }
  .errorMessage {
    margin-top: 6px;
    font-size: 13px;
    color: red;
    margin-bottom: 0 !important;
    margin-left: 1px;
  }
  .passHideShow {
    cursor: pointer;
    position: absolute;
    bottom: 7px;
    right: 14px;
    color: #707070;
    svg {
      background-color: transparent;
    }
  }
}

.MuiAutocomplete-root {
  .MuiInputBase-root {
    &:focus-visible {
      border-color: #c5c3c3 !important;
      outline: none !important;
    }
    &:hover {
      fieldSet {
        border: 1px solid $focusBorder !important;
      }
    }
  }
}
// Button
.custom-btn {
  button {
    border: none;
    color: #fff;
    font-style: normal;
    line-height: normal;
    margin: auto;
  }
}

// Title
.title-custom {
  margin-top: 10px;
  margin-left: 10px;
  h5 {
    color: #0570d4;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h6 {
    color: #585858;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

// Searching
.custom-searching {
  .showButtonInput {
    input {
      border-radius: 96px 0px 0px 96px;
      border: 1px solid #e9e9e9;
      font-size: 14px;
      background: #fbfbfb;
      height: 45px;
      padding: 17px;
      &:focus {
        outline: none;
      }
      ::placeholder {
        color: #a4a4a4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    button {
      border-radius: 0px 50px 50px 0px;
      padding: 0px 14px 0px 14px;
      border: none;
      background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
      height: 45px;
      svg {
        width: 20px;
        height: 20px;
        fill: white;
      }
    }
  }
  .showIcon {
    position: relative;
    svg {
      position: absolute;
      width: 23px;
      fill: #6a5dff;
      top: 7px;
      left: 12px;
    }
    input {
      border-radius: 96px;
      border: 1px solid #e9e9e9;
      font-size: 14px;
      background: #fbfbfb;
      height: 45px;
      padding: 17px;
      padding-left: 53px;
      width: 303px;
      &:focus {
        outline: none;
      }
      ::placeholder {
        color: #a4a4a4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

// Table
.custom-table {
  border-radius: 16px;
  margin-top: 15px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  position: relative;
  max-height: 462px;
  display: block;
  overflow: scroll;
  min-height: 462px;
  height: 100%;
  table {
    width: 100%;
    .not-dataFound {
      height: 358px;
      border-bottom: none;
      color: #585858;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      opacity: 0.8;
      line-height: normal;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      color: #fff;
      border-radius: 16px 16px 0px 0px;
      width: 100%;
      background: linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%);
      th {
        padding: 12px 16px;
        .table-head {
          font-weight: 500;
          font-size: 14px;
        }
        &:nth-child(1) {
          border-radius: 16px 0px 0px 0px;
        }
        &:nth-last-child(1) {
          border-radius: 0px 16px 0px 0px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 12px 20px;
          border: 1px solid #ede8e8;
          span {
            color: #585858;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            opacity: 0.8;
            line-height: normal;
          }
        }
      }
    }
  }
}

// Pagination
.custom-pagination {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
  .MuiTablePagination-root {
    .MuiToolbar-root {
      align-items: baseline !important;
      .MuiIconButton-root {
        border-radius: 8px;
        margin-left: 10px;
        border: 1px solid #dcd8d8;
        background: #fdfdfd;
        svg {
          font-size: 26px;
        }
      }
      .MuiSelect-select {
        border-radius: 8px;
        margin-left: 10px;
        border: 1px solid #dcd8d8;
        background: #fdfdfd;
        width: 43px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// Dialog
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0000006b;
  z-index: 5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 10000 !important;
  padding: 0 30px;

  .mainDiaogBox {
    background-color: #fff;
    padding: 20px;
    max-height: 98vh;
    overflow: auto;
    border-radius: 20px !important;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;

    .dialog-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        visibility: visible !important;
        color: #0570d4;
        font-size: 19px;
        margin: 0px;
        font-weight: 700;
      }
      .MuiButtonBase-root {
        cursor: pointer;
        svg {
          font-size: 26px;
          fill: #aaaaaa;
        }
      }
    }
    .dialog-body {
      overflow-x: hidden;
      max-height: 500px;
    }

    & .formBody {
      max-height: 600px;
      overflow: auto;

      & > div {
        margin-bottom: 10px;
      }
    }

    & .closeButton {
      font-size: 30px;
      background-color: $second;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      color: #fff;
      margin-left: auto;
      cursor: pointer;
    }

    // @include maxMediaQuery($md) {
    //   & h2 {
    //     font-size: 20px;
    //   }

    //   & .closeButton {
    //     font-size: 20px;
    //     height: 25px;
    //     width: 25px;
    //   }
    // }
  }
}
.custom-multi-value {
  display: flex;
  align-items: center;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  border-radius: 4px;
  padding: 4px;
  margin: 2px;
  box-sizing: border-box;
  .moreApp-option {
    margin-left: 4px;
    span {
      margin-left: 8px;
    }
  }
  .custom-multi-value-remove {
    margin: 1px 6px 0px 9px;
    cursor: pointer;
    font-size: 14px;
  }
}
.moreApp-optionList {
  padding: 5px;
  cursor: pointer;
  img {
    width: 30px;
    object-fit: cover;
    border-radius: 6px;
    height: 30px;
  }
  span {
    margin-left: 10px;
    font-size: 13px;
  }
}
// Image Input
.form-input[type="file"]::file-selector-button {
  margin-right: 10px;
  border: none;
  background: #f8f9fa;
  padding: 0px 20px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  height: 43px !important;
  color: #5e6f82;
  font-size: 14px;
  height: 38px;
  font-weight: 500;
  border-right: 1px solid #ececec;
}

.form-input[type="file"] {
  padding: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  border-radius: 6px;
  border: 1px solid #ececec;
  background: #fff;
  color: #5e6f82;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

//ToggleSwitch
.custom-toggleSwitch {
  .toggle {
    position: relative;
    overflow: hidden;
    input[type="checkbox"] {
      position: relative;
      appearance: none;
      padding: 0px !important;
      cursor: pointer;
      border-radius: 41px !important;
      border: 0.5px solid #f31111 !important;
      background: #ffebeb !important;
      width: 57px !important;
      height: 26px !important;
    }
    input:checked[type="checkbox"] {
      background: #daffd4 !important;
      border: 0.5px solid #1f9c0a !important;
    }
    input[type="checkbox"]::after {
      position: absolute;
      content: "";
      left: 3px;
      top: 50%;
      width: 20px;
      height: 20px;
      border-radius: 50% !important;
      transform: translateY(-50%);
      background: #f41111;
      transition: all 0.4s;
    }
    input:checked[type="checkbox"]::after {
      background: linear-gradient(180deg, #2bc312 0%, #1d9609 100%);
      left: 33px;
    }
    label {
      position: absolute;
      pointer-events: none;
      color: #000;
      transition: all 0.4s;
    }
    input:checked[type="checkbox"] ~ label.left {
      top: 41%;
      transform: translateY(-50%);
      left: 10px;
      color: #fff;
      font-size: 13px;
    }
    input:not(:checked[type="checkbox"]) ~ label.right {
      top: 41%;
      transform: translateY(-50%);
      right: 10px;
      color: #000;
      font-size: 13px;
    }
  }
}

.themeColor {
  color: $theme;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inputData {
  label {
    font-weight: 500 !important;
    opacity: 1 !important;
    margin-bottom: 1px;
  }
  textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ececec;
    padding: 15px;
    margin-top: 8px;
    font-size: 14px;
    background: white;

    &:focus {
      border-color: #c5c3c3 !important;
      outline: none;
    }

    &::placeholder {
      color: gray;
      font-size: 14px;
    }
  }
}

// .inputData input[type="file"] {
//   padding: 0px;
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden !important;
//   text-overflow: ellipsis;
// }
// @media screen and (max-width: 768px) {
//   .inputData input[type="file"] {
//     font-size: 14px;
//   }
// }
// @media screen and (max-width: 576px) {
//   .inputData input[type="file"] {
//     font-size: 10px;
//   }
// }
// .inputData input[type="file"]::file-selector-button {
//   margin-right: 15px;
//   border: none;
//   background: $theme;
//   padding: 0px 10px 8px;
//   color: #ffffff;
//   cursor: pointer;
//   transition: background 0.2s ease-in-out;
//   height: 47px;
// }
// @media screen and (max-width: 768px) {
//   .inputData input[type="file"]::file-selector-button {
//     width: 100px;
//     padding: 8px;
//     font-size: 14px;
//   }
// }
// @media screen and (max-width: 576px) {
//   .inputData input[type="file"]::file-selector-button {
//     width: 80px;
//     padding: 8px;
//     height: 37px;
//     font-size: 12px;
//   }
// }

.ad-revenue-box {
  border-radius: 12px;
  border: 1px solid #dce0e2;
  background: #fff;
  padding: 15px 10px 15px 11px;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
  h6 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: normal;
  }
  .custom-input {
    label {
      color: #7b7b7b;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 7px;
      margin-left: 1px;
    }
    input {
      border-radius: 6px;
      border: 1px solid #dce0e2;
      background: #fff;
    }
  }
}
.editTextDark {
  div[editext="view"] {
    color: #8c9fa9 !important;
  }
}
.other-setting-input {
  label {
    color: #4b4d58;
    font-family: Plus Jakarta Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }

  .input-box {
    input[editext="input"] {
      border-radius: 6px;
      height: 42px;
      border: 1px solid #dce0e2;
      background: #fff;
      font-size: 14px;
    }
    div[editext="view"] {
      font-size: 14px;
      padding-left: 10px;
      color: black;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
    div[editext="main-container"] {
      width: 100%;
      div[editext="view-container"] {
        width: 100%;
        justify-content: space-between;
        border-radius: 6px;
        height: 42px;
        border: 1px solid #dce0e2;
        background: #fff;
        .view {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          color: #8c9fa9;
          padding-left: 10px;
        }
        button[editext="edit-button"] {
          background-color: transparent;
          border: none;
          &::before {
            font-size: 20px;
            color: #93aab5;
          }
        }
      }
      div[editext="edit-container"] {
        position: relative;
        button {
          border: none;
          background-color: white;
          border: 1px solid #dce0e2;
          z-index: 9999;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
        }
        button[editext="save-button"] {
          position: absolute;
          right: 37px;
          top: 0;
          color: darkgreen;
          margin-right: 7px;
        }
        button[editext="cancel-button"] {
          position: absolute;
          right: 5px;
          border-radius: 0px 6px 6px 0px;
          top: 0;
        }
      }
    }
    .custom-toggleSwitch {
      display: flex;
      align-items: center;
      .toggle {
        display: flex;
        align-items: center;
      }
    }
  }
}

.skeleton-table {
  background: #e1e1e1;
  border-radius: 4px;
  height: 40px;
  position: relative;
  overflow: hidden;
  padding: 0px !important;
  margin: 6px -3px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    animation: tableSkeletonLoad 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}
.giftCategoryWiseLoader {


  
  .gift-Image{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e1e1e1;
    padding: 19px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin: 10px 10px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        to right,
        transparent 0%,
        #e8e8e8 50%,
        transparent 100%
      );
      animation: tableSkeletonLoad 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
    p {
      background-color: rgb(214 215 216);
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    h5 {
      width: 100px;
      height: 20px;
      background-color: rgb(214 215 216);
      border-radius: 10px;
      margin-top: 10px;
    }
    h4 {
      margin-top: 20px;
      width: 100px;
      height: 20px;
      background-color: rgb(214 215 216);
      border-radius: 10px;
    }
    .gift-button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .custom-btn{
        margin: 0px 5px;
        button{
          background: rgb(214 215 216);
        }
      }
    }
  }
}

@keyframes tableSkeletonLoad {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

#demo-customized-menu {
  ul {
    li {
      font-size: 14px !important;
      &:hover {
        background-color: rgb(0 0 0 / 10%) !important;
      }
    }
  }
}
